"use client"
import React from 'react'
import MovieMediumCard from './MovieMediumCard';
import MovieLargeCard from './MovieLargeCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid,Autoplay,Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function MovieCard({type,movies,breakpoints,pagination,useNavigation}) {
  const MovieComponents  = {
    'md': MovieMediumCard,
    'lg': MovieLargeCard,
  };
  const MovieComponent = MovieComponents[type];
  return (
      <Swiper
          navigation={useNavigation}
          breakpoints={breakpoints}
          autoplay={{
              delay: 5000,
              disableOnInteraction: false,
          }}
          pagination={pagination}
          spaceBetween={20}
          modules={[Grid,Autoplay,Pagination,Navigation]}
          className="mySwiper"
      >
        {
          movies.map((movie) => 
              <SwiperSlide key={'dow-'+movie.id}>
                <MovieComponent movie={movie} />
              </SwiperSlide>
          )
        }
      </Swiper>
  )
}
