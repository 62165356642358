// Import the necessary libraries
import { useMediaQuery } from 'react-responsive';
import tailwindConfig  from '@/tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig'

const {theme} = resolveConfig(tailwindConfig)
const { screens: breakpoints } = theme;
// Create the useBreakpoint function
function useBreakpoint(breakpointKey) {
  const bool = useMediaQuery({
    query: `(max-width: ${breakpoints[breakpointKey]})`,
  });
  const capitalizedKey = breakpointKey[0].toUpperCase() + breakpointKey.substring(1);
  const key = `isDown${capitalizedKey}`;
  const result = {};
  result[key] = bool;
  return result;
}

// Export the useBreakpoint function
export { useBreakpoint };
